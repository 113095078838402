import React, { useState } from "react";
import axios from 'axios';
import { Button } from "@material-tailwind/react";
import Modal from 'react-modal';

// Définir l'élément de l'application pour améliorer l'accessibilité
Modal.setAppElement('#root');  // Ici, 'root' est l'ID de l'élément racine de votre application React

const Adhesion = () => {
  const [reservationDetails, setReservationDetails] = useState({
    name: '',
    lastName: '',
    email: '',
    date: '',
    purpose: '',
    phone: '',
  });

  const [showModal, setShowModal] = useState(false);
  const [notification, setNotification] = useState(""); // State to store the notification message

  // Handle change for input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setReservationDetails({
      ...reservationDetails,
      [name]: value,
    });
  };

  // Handle form submission
  const handleReservationSubmit = async (e) => {
    e.preventDefault();
  
    // Validation des champs
    if (
      !reservationDetails.name ||
      !reservationDetails.lastName ||
      !reservationDetails.email ||
      !reservationDetails.date ||
      !reservationDetails.purpose ||
      !reservationDetails.phone
    ) {
      setNotification("Veuillez remplir tous les champs.");
      return;
    }
  
    // Validation supplémentaire pour l'email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(reservationDetails.email)) {
      setNotification("Veuillez entrer une adresse e-mail valide.");
      return;
    }
  
    try {
      // Appel à l'API
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/customers`, {
        Names: reservationDetails.name,
        LastName: reservationDetails.lastName,
        "E-mails": reservationDetails.email,
        Phones: reservationDetails.phone,
        starting_date: reservationDetails.date,
        notes: reservationDetails.purpose,
      });
  
      console.log("Success:", response);
      setNotification("Réservation soumise avec succès !");
      
      // Fermeture du modal après un délai
      setTimeout(() => {
        setShowModal(false);
      }, 2000);
  
      // Réinitialisation des champs
      setReservationDetails({
        name: "",
        lastName: "",
        email: "",
        date: "",
        purpose: "",
        phone: "",
      });
    } catch (error) {
      console.error("Error details:", error.response ? error.response.data : error.message);
      setNotification("Une erreur est survenue. Veuillez réessayer.");
    }
  };
  

  return (
    <div className="flex space-x-4">
      {/* Bouton Groupe de discussion */}
      {/* <Button
        variant="text"
        className="hover:bg-[#121212] bg-[#121212] rounded-none text-white h-10 text-xs flex flex-row items-center justify-center space-x-2 px-4"
      >
        <div className="flex items-center space-x-2">
          <svg
            width="20"
            height="20"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.75 11.25C5.12891 11.25 6.25 10.1289 6.25 8.75C6.25 7.37109 5.12891 6.25 3.75 6.25C2.37109 6.25 1.25 7.37109 1.25 8.75C1.25 10.1289 2.37109 11.25 3.75 11.25ZM21.25 11.25C22.6289 11.25 23.75 10.1289 23.75 8.75C23.75 7.37109 22.6289 6.25 21.25 6.25C19.8711 6.25 18.75 7.37109 18.75 8.75C18.75 10.1289 19.8711 11.25 21.25 11.25ZM22.5 12.5H20C19.3125 12.5 18.6914 12.7773 18.2383 13.2266C19.8125 14.0898 20.9297 15.6484 21.1719 17.5H23.75C24.4414 17.5 25 16.9414 25 16.25V15C25 13.6211 23.8789 12.5 22.5 12.5ZM12.5 12.5C14.918 12.5 16.875 10.543 16.875 8.125C16.875 5.70703 14.918 3.75 12.5 3.75C10.082 3.75 8.125 5.70703 8.125 8.125C8.125 10.543 10.082 12.5 12.5 12.5ZM15.5 13.75H15.1758C14.3633 14.1406 13.4609 14.375 12.5 14.375C11.5391 14.375 10.6406 14.1406 9.82422 13.75H9.5C7.01562 13.75 5 15.7656 5 18.25V19.375C5 20.4102 5.83984 21.25 6.875 21.25H18.125C19.1602 21.25 20 20.4102 20 19.375V18.25C20 15.7656 17.9844 13.75 15.5 13.75ZM6.76172 13.2266C6.30859 12.7773 5.6875 12.5 5 12.5H2.5C1.12109 12.5 0 13.6211 0 15V16.25C0 16.9414 0.558594 17.5 1.25 17.5H3.82422C4.07031 15.6484 5.1875 14.0898 6.76172 13.2266Z"
              fill="white"
            />
          </svg>
          <p className="whitespace-nowrap">{('Groupe de discussion')}</p>
        </div>
      </Button> */}

      {/* Bouton Réservation pour ouvrir le modal */}
      {/* <Button
        onClick={() => setShowModal(true)}
        variant="text"
        className="hover:bg-[#0a0a0a] bg-[#0a0a0a] text-white rounded-md px-4 py-2"
      >
        Réservation
      </Button>  */}

      {/* Modal avec formulaire de réservation */}
      <Modal 
        isOpen={showModal} 
        onRequestClose={() => setShowModal(false)}
        contentLabel="Formulaire de réservation"
        className="flex justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 z-50"
        overlayClassName="flex justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 z-50"
      >
        <div className="bg-white p-6 rounded-lg w-full max-w-md">
          <div className="text-center">
            <h2 className="text-xl font-semibold">{('Formulaire de réservation')}</h2>

            <form onSubmit={handleReservationSubmit}>
              <div className="mb-4">
                <input
                  type="text"
                  name="name"
                  value={reservationDetails.name}
                  onChange={handleChange}
                  placeholder={('Nom')}
                  className="border p-2 w-full rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="lastName"
                  value={reservationDetails.lastName}
                  onChange={handleChange}
                  placeholder={('Prénom')}
                  className="border p-2 w-full rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="email"
                  name="email"
                  value={reservationDetails.email}
                  onChange={handleChange}
                  placeholder={('Email')}
                  className="border p-2 w-full rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="tel"
                  name="phone"
                  value={reservationDetails.phone}
                  onChange={handleChange}
                  placeholder={('Téléphone')}
                  className="border p-2 w-full rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="date"
                  name="date"
                  value={reservationDetails.date}
                  onChange={handleChange}
                  className="border p-2 w-full rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <textarea
                  name="purpose"
                  value={reservationDetails.purpose}
                  onChange={handleChange}
                  placeholder={('Raison de la réservation')}
                  className="border p-2 w-full rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <button
                  type="submit"
                  className="bg-black text-white px-4 py-2 rounded-md w-full"
                >
                  Soumettre
                </button>
              </div>
            </form>
            <button
              onClick={() => setShowModal(false)}
              className="mt-4 bg-gray-500 text-white px-4 py-2 rounded-md w-auto"
            >
              Annuler
            </button>
          </div>
        </div>
      </Modal>

      {/* Notification Message */}
      {notification && (
        <div className="fixed bottom-5 left-1/2 transform -translate-x-1/2 bg-white text-black px-4 py-2 rounded-lg whitespace-nowrap overflow-hidden text-ellipsis max-w-screen-sm border border-gray-300 shadow-md">
          {notification}
        </div>
      )}
    </div>
  );
};


export default Adhesion;
